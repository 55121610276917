import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { green } from "@material-ui/core/colors"

import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"

// import checkboxStyles from "../../../assets/styles/modules/forms/checkbox.module.scss"

interface FormCheckboxProps {
  isLabelHidden?: boolean
  isCheckboxRequired?: boolean
  label: string
  name: string
  onChange: (e) => void
  isChecked: boolean
}

const WhiteCheckbox = withStyles({
  root: {
    color: "#fff",
    "&$checked": {
      color: "#fff",
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)

const FormCheckbox = (props: FormCheckboxProps) => {
  const { isCheckboxRequired, isChecked, name, onChange, label } = props

  return (
    <FormGroup row>
      <FormControlLabel
        label={label}
        control={
          <WhiteCheckbox
            name={name}
            onChange={onChange}
            required={isCheckboxRequired}
            checked={isChecked}
          />
        }
      />
    </FormGroup>
  )
}

export default FormCheckbox
