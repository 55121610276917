import React, { LegacyRef, useState } from "react"
import ReCAPTCHA from 'react-google-recaptcha'
import PrimarySubmitCta from "../../components/buttons/primary_submit_cta"
import FormCheckbox from "../../components/forms/form_checkbox"
import { InputNumber, InputString } from "../../components/forms/form_input"
import FormSelect from "../../components/forms/form_select"
import FormTextarea from "../../components/forms/form_textarea"
import { themes } from "../../components/buttons/primary_cta"

interface FormProps {
  addressStateValue: string
  handleChange: (e) => void
  handleLocationState: (e) => void
  handlePackToggle: (e) => void
  formSubmit: (e) => void
  isPackChecked: boolean
  states: string[]
  recaptchaRef: LegacyRef<ReCAPTCHA> | undefined
}

const recaptchaKey = process.env.SITE_RECAPTCHA_KEY || "";

const ContactForm = (props: FormProps) => {
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const {
    addressStateValue,
    formSubmit,
    handleChange,
    handleLocationState,
    handlePackToggle,
    isPackChecked,
    states,
    recaptchaRef
  } = props

  return (
    <form
      name="GetInvolved"
      method="POST"
      data-netlify="true"
      data-netlify-recaptcha="true"
      netlify-honeypot="bot-field"
      onSubmit={formSubmit}
    >
      <noscript>
        <p>This form won’t work with Javascript disabled</p>
      </noscript>
      {/*
            Required hidden field for Netlify to work.
            see https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/
          */}
      <input type="hidden" name="form-name" value="GetInvolved" />

      {/* 
            Honeypot security field: If filled out then the submission will go to SPAM.
          */}
      <p hidden={true}>
        <label>
          Don’t fill this out if you're human:
          <input type="hidden" name="bot-field" value="GetInvolved" />
        </label>
      </p>

      <div className="grid">
        <InputString
          label="Organisation"
          name="organisation"
          isInputRequired={true}
          onChange={handleChange}
          type="text"
        />
        <InputString
          label="First Name"
          name="first_name"
          isInputRequired={true}
          onChange={handleChange}
          type="text"
        />
        <InputString
          label="Last Name"
          name="last_name"
          isInputRequired={true}
          onChange={handleChange}
          type="text"
        />
        <InputString
          label="Email"
          name="email"
          onChange={handleChange}
          type="email"
        />
        <InputNumber label="Postcode" name="postcode" onChange={handleChange} />

        <FormTextarea
          label="Your message"
          name="your_message"
          onChange={handleChange}
        />

        <FormCheckbox
          isChecked={isPackChecked}
          label="Send me a refiller info and sticker pack"
          name="send_me_a_pack"
          onChange={handlePackToggle}
        />
      </div>

      {/* 
            Should be hidden but because Netlify reads the 
            initial static HTML we have to 'hide' it with CSS only 
          */}
      <div className={`grid ${isPackChecked ? "show" : "hide"}`}>
        <InputString
          label="Street Address"
          name="street_address"
          onChange={handleChange}
          type="text"
        />

        <InputString
          label="Suburb"
          name="suburb"
          onChange={handleChange}
          type="text"
        />

        <InputNumber
          label="Postcode"
          name="postcode_pack"
          onChange={handleChange}
        />
        <FormSelect
          defaultValue="Select a state"
          label="State"
          name="state"
          onChange={handleLocationState}
          options={states}
          value={addressStateValue}
        />
      </div>

      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={recaptchaKey}
        onChange={() => setButtonDisabled(false)}
      />

      <div className="util__margin-top--25">
        <PrimarySubmitCta
          isSubmitButton={true}
          theme={themes.white}
          label="Submit"
          disabled={buttonDisabled}
        />
      </div>
    </form>
  )
}

export default ContactForm
