import React from "react"
import InputLabel from "@material-ui/core/InputLabel"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"

interface SelectProps {
  defaultValue?: string
  label: string
  name: string
  options: string[]
  onChange: (e) => void
  value: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
)
const Dropdown = (props: SelectProps) => {
  const { defaultValue, name, onChange, options = [], value } = props

  const classes = useStyles()
  const [labelWidth] = React.useState(0)

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">State</InputLabel>

      <Select
        native
        value={value}
        onChange={onChange}
        labelWidth={labelWidth}
        inputProps={{
          name: "State",
          id: "outlined-age-native-simple",
        }}
      >
        {defaultValue && (
          <option defaultValue={defaultValue}>{defaultValue}</option>
        )}
        {options.map((opt) => (
          <option key={opt} value={opt}>
            {opt}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

export default Dropdown
